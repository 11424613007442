import './navbar.scss'
import logo from '../../../assets/images/pnglogo.png';
import menuIcon from "../../../assets/images/sidebar-icon.png";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const { hash } = useLocation();
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] =
  useState<boolean>(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      console.log(window.scrollY);
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={scrolled?'navbar-scrolled':`navbar`}>
      <div className='navbar__left'>
        <div><img src={logo} height={56} alt='logo'/></div>
        <div>Bhagwati Garden</div>
      </div>
      <div
        className={`navbar__right  menu-toggle ${
          !isMobileSidebarOpen && "sm-disp-none"
        }`}
      >
          <div className="sidebar-top">
            <div
              className="close-sidebar"
              onClick={() => setIsMobileSidebarOpen(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 50 50"
                overflow="visible"
                stroke="#fff"
                stroke-width="10"
                stroke-linecap="round"
              >
                <line x2="50" y2="50" />
                <line x1="50" y2="50" />
              </svg>
            </div>
          </div>
          <a href='#home' 
              className={`${scrolled?'navbar-scrolled':'navbar'}__right__item ${hash === '#home' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Home
          </a>
          <a href='#about' 
              className={`${scrolled?'navbar-scrolled':'navbar'}__right__item ${hash === '#about' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                About
          </a>
          <a href='#services' 
              className={`${scrolled?'navbar-scrolled':'navbar'}__right__item ${hash === '#services' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Services
          </a>
          <a href='#contact' 
              className={`${scrolled?'navbar-scrolled':'navbar'}__right__item ${hash === '#contact' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Contact Us
          </a>
          <a href='#reviews' 
              className={`${scrolled?'navbar-scrolled':'navbar'}__right__item ${hash === '#reviews' && 'nav-item-selected'}`}
              onClick={() => setIsMobileSidebarOpen(false)}>
                Reviews
          </a>
      </div>
      <div
        className={`${scrolled?'sidebar-menu-icon-scrolled':'sidebar-menu-icon'}`}
        onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
      >
        <img src={menuIcon} height={38} alt={menuIcon} />
      </div>
    </div>
  )
}

export default Navbar
