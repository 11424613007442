import { ReactGoogleReviews } from "react-google-reviews";
import './reviews.scss'
import ReviewList from "../../shared/components/Reviews";
const Reviews = () =>{
    return (
        <div className="reviews" id="reviews">
            <div className="reviews__head">
                <div className="reviews__head__left">
                    <div className="reviews__head__left__heading">Words from Our Guests</div>
                    <div className="reviews__head__left__sub-heading"> The heartfelt experiences and joyous celebrations of our clients at Bhagwati Garden reflect our dedication to creating unforgettable Indian weddings and receptions with excellent service and care.</div>
                </div>
                <div className="reviews__head__right">
                    <ReactGoogleReviews layout="badge" featurableId='490f1ac7-43cf-41f5-9bda-454a89f69166' />
                </div>
            </div>
            <div className="reviews__body">
                <ReviewList/>
            </div>
        </div>
    )
}
export default Reviews;