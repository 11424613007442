import './footer.scss'
import instaLogo from '../../../assets/images/iconinsta.svg'
import logo from '../../../assets/images/pnglogo.png'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer__upper'>
        <div className='footer__upper__left'>
            <div className='brand-contain'><img src={logo} height={70} alt='logo'/> <span className='brand-name'>Bhagwati Garden</span></div>
            <div className='footer__upper__left__content'> Your dream wedding starts here - let's make memories that last forever
              <div className='footer__upper__left__content__insta'><img src={instaLogo} alt='insta-logo' height={24}/><span>Follow us for all the latest updates @ bhagwatigarden_prayagraj</span></div> </div>
        </div>
        <div className='footer__upper__right'>
            <div><div className='footer-content-heading'>Contact:</div> <div>+91 9453464556</div></div>
            <br />
            <div><div className='footer-content-heading'>Address:</div> <div>41/24, Nyaya Marg, <div>Prayagraj, Uttar Pradesh, 211001</div></div></div>
            <br />
            <div><div className='footer-content-heading'>Inquiries @:</div> <div>kapoorsangeeta100@gmail.com</div></div>
        </div>
      </div>
      <div className='footer__lower'>
      ©2024 BHAGWATI GARDEN. ALL RIGHTS RESEVED.
      </div>
    </div>
  )
}

export default Footer
