/* eslint-disable */

import { useEffect, useState } from 'react';
import './home.scss';
import Services from '../Services';
import ContactUs from '../ContactUs';
import Reviews from '../Reviews';
import Footer from '../../shared/components/Footer';

const Home = () => {

  return (
    <div className='home'>
      <div className={`home-landing-img`} id="home" />
      <div className='home__bg-text'>
        <div className='home-body'>
          <div className='home-heading'>
            <h2><span className='home-heading__text'>YOUR DREAM WEDDING AWAITS</span></h2>
          </div>
        </div>
      </div>
      <div className='home-body about-outer' id="about">
        <div className='home-section1 about'>
          <div className='about__details'>
              <div className='about__details__heading'>
                ABOUT US
              </div>
              <div className='about__details__text'>
              Got a breezy, starlit wedding on your mind? 
              Bhagwati Garden is the perfect venue for you. 
              With a lush green lawn, ideal for grand Indian weddings, receptions, and pre-wedding ceremonies.
              <span className='only-web'>The spacious banquet hall accommodates hundreds of guests, making it a great choice for large gatherings. 
              Whether you're planning a traditional, cultural, or social event with guest lists ranging from 200 to 2000, 
              we handle it all. </span>The expert team of in-house decorators, caterers, 
              and event managers ensures that every detail of your event is executed flawlessly.
              </div>
          </div>
          <div className='about__pic'></div>
        </div>
      </div>
      <Services/>
      <ContactUs />
      <Reviews />

      <Footer />
    </div>
  )
}

export default Home
