import './reviewBox.scss'
type reviewerType = {
    displayName: string;
    profilePhotoUrl: string;
    isAnonymous: boolean
}
type reviewType = {
    reviewId: string|null;
    reviewer: reviewerType;
    comment: string;
    starRating: number;
} 
const ReviewBox = ({reviewId, reviewer, comment, starRating}:reviewType) => {
    return (
        <div className="review" key={reviewId}>
            <div className='review__content'>{comment}</div>
            <div className='review__head'>
                <img src={reviewer?.profilePhotoUrl} alt='profilePic' height={44}/>
                <div className='review__head__name'>{reviewer?.displayName}</div>
            </div>
        </div>
    );
}
export default ReviewBox;