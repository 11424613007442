import { ReactGoogleReviews } from "react-google-reviews";
import  './reviews.scss';
import ReviewBox from "../ReviewBox";
const Reviews = () =>{
    return(
        <ReactGoogleReviews layout="custom" featurableId='490f1ac7-43cf-41f5-9bda-454a89f69166' renderer={(reviews) => {
            return (
              <div className="reviews-list">
                {reviews.filter(({ starRating, comment }) => (starRating >= 4 && comment.length>120 && comment.length<300))
                .map(({ reviewId, reviewer, comment, starRating }) => (
        
                  <ReviewBox reviewId={reviewId} reviewer={reviewer} comment={comment} starRating={starRating}/>
                ))}
              </div>
            );
          }} />
    )
}
export default Reviews;